import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { getKeyValue, setKeyValue } from '@/lib/utils';

const SessionStorageContext = createContext(null);

const sessionStorageKey = 'session-content';

export function SessionStorageProvider({ children }) {
  const [settings, setSettings] = useState();

  useEffect(() => {
    const storedContent = sessionStorage.getItem(sessionStorageKey);
    if (!storedContent) return;
    setSettings(JSON.parse(storedContent));
  }, []);

  const set = useCallback((key, value) => {
    setSettings((prev) => {
      const newSettings = setKeyValue(prev, key, value);
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(newSettings));
      return newSettings;
    });
  }, []);

  const get = useCallback(
    (key, defaultValue = null) => getKeyValue(settings, key, defaultValue),
    [settings],
  );

  const has = useCallback((key) => get(key) !== undefined, [get]);

  const unset = useCallback((key) => {
    set(key, undefined);
  }, [set]);

  const values = useMemo(() => ({
    set,
    get,
    has,
    unset,
  }), [set, get, has, unset]);

  return (
    <SessionStorageContext.Provider value={values}>
      {children}
    </SessionStorageContext.Provider>
  );
}

export function useSessionStorage(key, defaultValue = null) {
  const { get, set } = useContext(SessionStorageContext);
  const value = useMemo(() => get(key, defaultValue), [get, key, defaultValue]);

  const setValue = useCallback((newValue) => {
    set(key, newValue);
  }, [key, set]);

  return [value, setValue];
}
