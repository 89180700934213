import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button } from '@/components/ui/button.jsx';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover.jsx';
import AuthMiddleware from "@/Middlewares/AuthMiddleware.js";
import { useUser } from "@/contexts/UserContext.jsx";
import { mutationFn } from "@/components/Hooks/useApiMutation.js";
import { useAuth } from "@/contexts/AuthContext.jsx";

const offset = 6;

const TutorialContext = createContext(null);

const Spotlight = ({ anchorEl }) => {
  const [rect, setRect] = useState({ top: 0, left: 0, width: 0, height: 0 });

  useEffect(() => {
    if (!anchorEl) return;

    const updatePosition = () => {
      const { top, left, width, height } = anchorEl.getBoundingClientRect();
      setRect({ top, left, width, height });
    };

    const observer = new ResizeObserver(updatePosition);
    observer.observe(anchorEl);

    updatePosition();

    window.addEventListener('scroll', updatePosition, true);

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', updatePosition, true);
    };
  }, [anchorEl]);

  if (!anchorEl) {
    return <div className="fixed inset-0 bg-black/50" />;
  }

  return (
    <React.Fragment>
      <div
        className="fixed rounded-md shadow-[0_0_0_9999px_rgba(0,0,0,0.5)]"
        style={{
          top: rect.top - offset,
          left: rect.left - offset,
          width: rect.width + offset * 2,
          height: rect.height + offset * 2,
        }}
      />
      <div
        className="fixed rounded-md"
        style={{
          top: rect.top - offset,
          left: rect.left - offset,
          width: rect.width + offset * 2,
          height: rect.height + offset * 2,
        }}
      />
    </React.Fragment>
  );
};

function TutorialPopper({ anchorEl, children }) {
  const [rect, setRect] = useState({ top: 0, left: 0, width: 0, height: 0 });

  useEffect(() => {
    if (!anchorEl) return;

    const updatePosition = () => {
      const { top, left, width, height } = anchorEl.getBoundingClientRect();
      setRect({ top, left, width, height });
    };

    const observer = new ResizeObserver(updatePosition);
    observer.observe(anchorEl);

    updatePosition();

    window.addEventListener('scroll', updatePosition, true);

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', updatePosition, true);
    };
  }, [anchorEl]);

  if (!anchorEl) {
    return <div className="fixed inset-0 bg-black/50" />;
  }

  return (
    <Popover
      open
    >
      <PopoverTrigger asChild>
        {anchorEl ? (
          <div
            className="pointer-events-none fixed"
            style={{
              top: rect.top - offset,
              left: rect.left - offset,
              width: rect.width + offset * 2,
              height: rect.height + offset * 2,
            }}
          />
        ) : (
          <div
            className="pointer-events-none fixed left-1/2 top-1/2"
          />
        )}
      </PopoverTrigger>
      <PopoverContent
        className="z-[110] flex flex-col gap-6 overflow-hidden rounded-xl border-none p-0"
      >
        {children}
      </PopoverContent>
    </Popover>
  );
}

export function TutorialProvider({ enabled = true, children }) {
  const { user } = useUser();
  const { auth } = useAuth();

  const [tutorials, setTutorials] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [tutorialSteps, setTutorialSteps] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTutorials = useCallback(async () => {
    const response = await mutationFn(
      'GET',
      [AuthMiddleware],
      { endpoint: `api/tutorial/wanted-tutorials/${user?.id}` },
      { auth, user }
    );
    return response.data;
  }, [user, auth]);

  const loadTutorials = useCallback(async () => {
    if (!user) return;
    const tutorialData = await getTutorials();
    setTutorials(tutorialData);
    setLoading(false);
  }, [user, getTutorials]);

  useEffect(() => {
    loadTutorials();
  }, [user, loadTutorials]);

  const registerStep = useCallback((node, title, subTitle, message, key, page, priority) => {
    if (tutorials.includes(page)) {
      return null;
    }

    const stepId = Math.random().toString(36).substring(7);
    setTutorialSteps((prev) => {
      const newSteps = [...prev];
      newSteps.push({
        id: stepId,
        node,
        title,
        subTitle,
        message,
        key,
        page,
        priority: priority || 0,
        read: false,
      });
      return newSteps;
    });
    return stepId;
  }, [tutorials]);

  const unregisterStep = useCallback((id) => {
    setTutorialSteps((prev) => [...prev].filter((s) => s.id !== id));
  }, []);

  const readStep = useCallback(() => {
    if (tutorialSteps.length === 0) return;
    if (currentStep === tutorialSteps.length - 1) {
      endTutorial(tutorialSteps[currentStep].page);
    }
    setCurrentStep((prev) => prev + 1);
  }, [tutorialSteps, currentStep]);

  const endTutorial = useCallback(async (tutorial_page) => {
    await mutationFn(
      'POST',
      [AuthMiddleware],
      { endpoint: `api/tutorial/end-tutorial`, body: { page: tutorial_page, user: user?.id } },
      { auth, user }
    );
    setCurrentStep(0);
    setLoading(true);
    loadTutorials();
  }, [user, auth, loadTutorials, currentStep]);

  useEffect(() => {
    if (tutorialSteps[currentStep]?.node) {
      tutorialSteps[currentStep].node.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [currentStep, tutorialSteps]);

  const tutorialValues = useMemo(() => ({
    registerStep,
    unregisterStep,
    readStep,
  }), [registerStep, unregisterStep, readStep]);

  return (
    <TutorialContext.Provider value={tutorialValues}>
      {children}
      {enabled && !loading && tutorialSteps[currentStep] && (
        <div className="fixed inset-0 z-[100] overflow-auto">
          <Spotlight anchorEl={tutorialSteps[currentStep].node} />
          <TutorialPopper anchorEl={tutorialSteps[currentStep].node}>
            <div className="flex justify-between bg-gradient-to-r from-[#FF585D] from-15% to-[#FFC100] to-[87%] px-4 py-2 font-body text-xl font-semibold text-white">
              <span>{tutorialSteps[currentStep].title}</span>
              <span>{currentStep + 1} / {tutorialSteps?.length}</span>
            </div>
            <div className="text-md text-sm font-semibold px-4 font-semibold text-primary">
              {tutorialSteps[currentStep].subTitle}
            </div>
            <div className="text-md text-sm px-4 text-primary -mt-3">
              {tutorialSteps[currentStep].message}
            </div>
            <div className="flex justify-center gap-2 p-2 pb-6">
              <Button
                type="button"
                onClick={() => endTutorial(tutorialSteps[currentStep]?.page)}
                size="small"
                className="rounded-full border border-black bg-white px-5 text-sm font-semibold text-black hover:bg-white/90"
              >
                Überspringen
              </Button>
              <Button
                type="button"
                onClick={() => readStep(tutorialSteps[currentStep].id)}
                size="small"
                className="rounded-full px-8 py-2 text-sm font-semibold"
              >
                Weiter
              </Button>
            </div>
          </TutorialPopper>
        </div>
      )}
    </TutorialContext.Provider>
  );
}

export const useTutorial = () => useContext(TutorialContext);

export const useTutorialStep = (title, subTitle, message, key, page, priority) => {
  const [node, setNode] = useState(null);

  const { registerStep, unregisterStep } = useTutorial();

  const setRef = useCallback((ref) => {
    setNode(ref);
  }, []);

  useEffect(() => {
    if (!node) return () => {};
    const id = registerStep(node, title, subTitle, message, key, page, priority);
    return () => {
      unregisterStep(id);
    };
  }, [node, title, subTitle, message, key, page, priority, registerStep, unregisterStep]);

  return [setRef];
};

export const useTutorialInfo = (title, subTitle, message, key, page, priority) => {
  const { registerStep, unregisterStep } = useTutorial();

  useEffect(() => {
    const id = registerStep(null, title, subTitle, message, key, page, priority);
    return () => {
      unregisterStep(id);
    };
  }, [title, subTitle, message, key, page, priority, registerStep, unregisterStep]);
};
