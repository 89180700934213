import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@/components/ui/icon-button.jsx';
import { LogOut } from 'lucide-react';
import {
  Bars3Icon,
  HomeIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';

import { cn } from '@/lib/utils';
import { useNavigate } from '@tanstack/react-router';
import { useAuth } from '@/contexts/AuthContext.jsx';
import FeedbackIcon from '@/components/Icons/Outlines/FeedbackIcon.jsx';
import {
  PlatformLayoutIndex as HomeRoute,
  PlatformLayoutFeedback as FeedbackRoute,
  PlatformLayoutHelpIndex as HelpOverview,
} from '@/routePaths.gen.js';
import { Button } from '@/components/ui/button.jsx';
import { useTutorialStep } from '@/providers/TutorialProvider.jsx';

const NavButton = React.forwardRef(({
  className = '', onClick, icon, text,
}, ref) => (
  <React.Fragment>
    <IconButton
      ref={ref}
      className={cn('hidden md:flex', className)}
      onClick={onClick}
      icon={icon}
    />
    <Button
      className={cn('flex w-full flex-row items-center justify-center gap-3 rounded-full md:hidden', className)}
      onClick={onClick}
    >
      <div className="flex max-w-32 flex-1 flex-row items-center justify-between gap-3">
        <div className="w-7">
          {icon}
        </div>
        <p className="flex-1 text-left font-semibold">{text}</p>
      </div>
    </Button>
  </React.Fragment>
));

NavButton.displayName = 'NavButton';

NavButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default function NavSidebar({ className = '' }) {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleHomeClick = useCallback(() => {
    navigate({ to: HomeRoute });
    setIsMobileMenuOpen(false);
  }, [navigate]);

  const handleFeedbackClick = useCallback(() => {
    navigate({ to: FeedbackRoute });
    setIsMobileMenuOpen(false);
  }, [navigate]);

  const handleHelpOverviewClick = useCallback(() => {
    navigate({ to: HelpOverview });
    setIsMobileMenuOpen(false);
  }, [navigate]);

  const handleLogoutClick = useCallback(() => {
    logout();
  }, [logout]);

  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen((prev) => !prev);
  }, []);

  const [setDashboardRef] = useTutorialStep(
    'Dashboard',
    'Die Übersicht über Ihre Adboards.',
    'Mit diesem Button gelangen Sie zur Übersicht aller Adboards, zu denen Sie eingeladen wurden.',
    'nav-sidebar-dashboard',
    'landing-page',
    1
  );
  const [setFeedbackRef] = useTutorialStep('Feedback',
    'Geben Sie uns Feedback zur Plattform.',
    'Im Feedbackforum können Sie Ihre Verbesserungsvorschläge zur Plattform sowohl öffentlich als auch anonym einreichen. ',
    'nav-sidebar-feedback',
    'landing-page',
    2
    );
  const [setHelpRef] = useTutorialStep(
    'Hilfe',
    'Haben Sie Fragen?',
    'In unseren FAQs beantworten wir zahlreiche Fragen zur Plattform. Über diesen Button erreichen Sie auch unseren Support.',
    'nav-sidebar-help',
    'landing-page',
    3
  );

  return (
    <React.Fragment>
      <IconButton
        onClick={toggleMobileMenu}
        className="fixed left-5 top-[12px] z-50 bg-primary text-white hover:bg-primary/95 md:hidden"
        icon={<Bars3Icon className="size-6 stroke-2" />}
      />
      <nav
        data-open={isMobileMenuOpen}
        className={cn(
          'data-[open="true"]:flex data-[open="false"]:hidden md:data-[open]:flex gap-4 md:gap-0 w-full '
          + 'bg-[#f2f2f4] z-10 fixed text-white md:text-black md:sticky left-0 top-[64px] md:top-0 h-screen '
          + 'md:w-20 flex-col items-start justify-start md:justify-between md:bg-gradient-to-t '
          + 'md:from-[#FF585D] md:from-[-9%] md:to-[#FFC100] md:to-[106%] flex',
          className,
        )}
      >
        <div className="mx-auto mt-4 flex w-full flex-col gap-4 px-8 md:w-auto md:gap-8 md:px-0">
          <NavButton
            ref={setDashboardRef}
            onClick={handleHomeClick}
            text="Übersicht"
            icon={<HomeIcon className="size-5 stroke-[1.5] md:size-6" />}
          />
        </div>
        <div className="mx-auto mb-4 flex w-full flex-col-reverse gap-4 px-8 md:w-auto md:gap-8 md:px-0">
          <NavButton
            onClick={handleLogoutClick}
            text="Abmelden"
            icon={<LogOut className="size-5 stroke-2 md:size-6" />}
          />
          <NavButton
            ref={setHelpRef}
            onClick={handleHelpOverviewClick}
            text="Hilfe"
            icon={<QuestionMarkCircleIcon className="size-6 stroke-[1.5] md:size-7" />}
          />
          <NavButton
            ref={setFeedbackRef}
            onClick={handleFeedbackClick}
            text="Feedback"
            icon={<FeedbackIcon className="size-8 stroke-2 md:absolute md:size-10" />}
          />
        </div>
      </nav>
    </React.Fragment>
  );
}

NavSidebar.propTypes = {
  className: PropTypes.string,
};
