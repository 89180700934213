import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from "@/components/ui/button.jsx";
import { useUser } from "@/contexts/UserContext.jsx";

const frequencies = [
  {
    id: 1,
    value: 'weekly',
    label: 'Bitte senden Sie mir einmal wöchentlich eine E-Mail'
  },
  {
    id: 2,
    value: 'monthly',
    label: 'Bitte senden Sie mir einmal im Monat eine E-Mail'
  },
  {
    id: 3,
    value: 'never',
    label: 'Bitte senden Sie mir keine E-Mails zu Aktivitäten'
  }
];

function NotificationSettings({ isPopup = false }) {
  const { user, updateNotificationFrequency } = useUser();
  const frequency = useMemo(() => user.email_notification_frequency, [user]);

  const handleSettingsUpdate = (event) => {
    if (frequency === event.target.value) return;
    updateNotificationFrequency(event.target.value);
  };
  return (
    <React.Fragment>
      <div className="flex flex-col gap-1">
        <p>Wir möchten Sie regelmäßig über Updates zu Aktivitäten auf unserer Plattform informieren.</p>
        <p>Sie erhalten ausschließlich Informationen zu den Adboards und Foren, an denen Sie teilnehmen.</p>
        <p>Selbstverständlich können Sie diese Einstellungen jederzeit anpassen.</p>
      </div>
      <p className="font-semibold">Wie oft möchten Sie über Aktivitäten auf der Plattform informiert werden?</p>
      <div className={`flex flex-col gap-4 ${isPopup ? 'items-center sm:items-start' : ''}`}>
        {frequencies.map((option) => (
          <div key={option.id} className="flex items-center gap-2">
            <input type="radio" name="notification" id={option.value} value={option.value}
                   className="cursor-pointer" onChange={handleSettingsUpdate}
                   checked={frequency === option.value}/>
            <label className="cursor-pointer" htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}


NotificationSettings.propTypes = {
  className: PropTypes.string,
};

export default NotificationSettings;
