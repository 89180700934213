import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@/components/Popups/Modal.jsx';
import { useNotification } from '@/components/Notification/NotificationProvider.jsx';
import InView from '@/components/Navigation/InView.jsx';
import Spinner from '@/components/ui/spinner.jsx';
import { InboxIcon } from '@heroicons/react/24/outline/index';
import NotificationCard from '@/components/Notification/NotificationCard.jsx';
import { Cog8ToothIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button } from "@/components/ui/button.jsx";
import { useUser } from "@/contexts/UserContext.jsx";
import { Tooltip, TooltipArrow, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip.jsx";
import { mutationFn } from "@/components/Hooks/useApiMutation.js";
import AuthMiddleware from "@/Middlewares/AuthMiddleware.js";
import NotificationSettings from "@/components/Popups/NotificationSettings.jsx";

function Notification({ open, onClose, ...props }) {
  const { notifications, loadNext, status, updateNotifications } = useNotification();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const { user } = useUser();

  const handleClose = useCallback(() => {
    onClose();
    setIsSettingsOpen(false);
  }, [onClose]);

  const handleDeleteAllNotifications = useCallback(() => {
    mutationFn(
      'POST',
      [AuthMiddleware],
      { endpoint: `api/notification/delete`, body: { user: user?.id } },
    ).then(() => {
      updateNotifications([]);
    });
  }, [user, updateNotifications]);

  return (
    <Modal open={open} onClose={handleClose}
           title={isSettingsOpen ? "Benachrichtigungseinstellungen" : "Benachrichtigungen"}
           className="left-auto *:text-left right-0 top-16 translate-x-0 translate-y-0 data-[state=closed]:slide-out-to-left-2 data-[state=open]:slide-in-from-right-1 sm:right-4" {...props}>
      {isSettingsOpen ? (
        <div className="py-8 mb-2 flex flex-col gap-6 sm:px-12 lg:px-16 px-6 sm:w-[620px] lg:w-[800px]">
          <NotificationSettings isPopup/>
          <Button className="rounded-full mt-2 w-56 mx-auto text-[15px]" onClick={() => setIsSettingsOpen(false)}>
            Zurück
          </Button>
        </div>
      ) : (
        <div className="py-6 sm:w-[620px] lg:w-[800px]">
          <div className="flex max-h-96 flex-col gap-4 overflow-y-auto px-4 sm:px-6 md:max-h-[40rem]">
            {notifications.map((notification) => (
              <NotificationCard key={notification.id} notification={notification}/>
            ))}
            {notifications.length === 0 && status !== 'pending' && (
              <div className="my-16">
                <InboxIcon className="mx-auto mb-6 size-16 text-gray-600"/>
                <p className="text-center font-semibold">Keine Benachrichtigungen</p>
              </div>
            )}
            <InView onEnter={loadNext}/>
            {status === 'pending' && (
              <div className="h-24">
                <Spinner width="70px" height="70px" className="z-30 flex h-20 justify-center text-primary"/>
              </div>
            )}
          </div>
        </div>
      )}
      {!isSettingsOpen && (
        <React.Fragment>
          <div className="absolute right-12 top-3 flex">
            <Button
              onClick={() => handleDeleteAllNotifications()}
              className="cursor-pointer bg-transparent px-2 sm:px-4 text-white opacity-70 hover:bg-transparent hover:opacity-100"
              autoFocus={false}
              tabIndex={-1}>
              <TooltipProvider>
                <Tooltip delayDuration={200}>
                  <TooltipTrigger>
                    <TrashIcon className="size-9 bg-red-500 rounded-full p-1"/>
                  </TooltipTrigger>
                  <TooltipContent
                    className="max-w-72 !animate-none text-wrap rounded-xl border-none bg-[#3F3F46] p-4 font-medium text-white"
                    side="bottom"
                  >
                    Alle Benachrichtigungen löschen
                    <TooltipArrow className="size-8 h-3 w-6 fill-[#3F3F46]"/>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Button>
            <Button
              onClick={() => setIsSettingsOpen(true)}
              className="cursor-pointer bg-transparent px-2 sm:px-4 text-white opacity-70 hover:bg-transparent hover:opacity-100">
              <Cog8ToothIcon className="size-9"/>
            </Button>
          </div>
        </React.Fragment>
      )
      }
    </Modal>
  );
}

Notification.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Notification;
