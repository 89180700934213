import { createFileRoute } from '@tanstack/react-router';
import { mutationFn } from '@/components/Hooks/useApiMutation';
import AuthMiddleware from '@/Middlewares/AuthMiddleware';

export const Route = createFileRoute('/_reduced-layout/adboard/$adboard/meeting/$breakout')({
  loader: async ({ params: { adboard: adboardId, breakout: breakoutId }, context }) => {
    const id = adboardId.split('-').pop();
    const data = await mutationFn('GET', [AuthMiddleware], { endpoint: `/api/adboards/${id}` }, context);
    const { adboard } = data.data;
    const { breakout_sessions: breakoutSessions } = adboard;
    const { meeting } = breakoutSessions.find((session) => session.id === breakoutId) || {};

    return {
      adboard,
      meeting,
    };
  },
});
