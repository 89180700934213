import React from 'react';
import { Link } from '@tanstack/react-router';
import UserContainer from '@/components/Forums/UserContainer.jsx';
import PropTypes from 'prop-types';

function NotificationCard({ notification }) {
  const { sender, link, time_elapsed: timeElapsed, content, message } = notification;

  return (
    <Link to={link} target="_blank"
          className="relative flex flex-col gap-4 rounded-3xl bg-white p-4 hover:shadow sm:px-6">
      <UserContainer className="flex sm:items-center justify-between text-left sm:flex-row lg:gap-10" user={sender}>
        <div>
          <p dangerouslySetInnerHTML={{ __html: message }}/>
          <p dangerouslySetInnerHTML={{ __html: content }}/>
        </div>
        <p className="mb-auto ml-auto text-nowrap text-gray-400">{timeElapsed}</p>
      </UserContainer>
    </Link>
  );
}

NotificationCard.propTypes = {
  notification: PropTypes.object.isRequired,
};

export default NotificationCard;
