import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import AuthMiddleware from '@/Middlewares/AuthMiddleware';
import {useAuth} from '@/contexts/AuthContext.jsx';
import {mutationFn} from '@/components/Hooks/useApiMutation';

export const UserContext = createContext();

function useUserQuery(auth) {
  const authenticated = auth.isAuthenticated();
  const {
    data,
    ...rest
  } = useQuery({
    queryKey: ['/api/profile/me', authenticated],
    queryFn: async () => mutationFn('GET', [AuthMiddleware], {endpoint: '/api/profile/me'}, {auth, user: {}}),
    initialData: {data: {user: null}},
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 30,
    refetchInterval: 1000 * 60 * 30,
    enabled: authenticated,
    retry: false,
  });

  if (!data || !data.data || !data.data.user) {
    return { user: null, authenticated, ...rest };
  }

  return { user: data.data.user, authenticated, ...rest };
}

const READY_INTERVAL = 100;

export function UserProvider({children}) {
  const auth = useAuth();
  const {user, ...rest} = useUserQuery(auth);
  const userRef = useRef(user);
  const statusRef = useRef(rest);
  const queryClient = useQueryClient();

  useEffect(() => {
    userRef.current = user;
    statusRef.current = rest;
  }, [user, rest]);

  const ready = useCallback(
    async () => new Promise((resolve, reject) => {
      const checkUser = setInterval(async () => {
        if (userRef.current && !statusRef.current.isPending) {
          clearInterval(checkUser);
          resolve(userRef.current);
        }

        if ((statusRef.current && statusRef.current.isFetched && statusRef.current.isError) || !statusRef.current || !statusRef.current.authenticated) {
          clearInterval(checkUser);
          // Clear the user
          userRef.current = null;
          statusRef.current = null;
          queryClient.clear();
          await queryClient.invalidateQueries();

          reject();
        }
      }, READY_INTERVAL);
    }),
    [],
  );

  const hasPermission = useMemo(
    () => (permissionSlug) => {
      if (!userRef.current || userRef.current.isPending) {
        return false;
      }

      return userRef.current.permissions.includes(permissionSlug);
    },
    [],
  );

  const hasRole = useMemo(
    () => (roleSlug) => {
      if (!userRef.current) {
        return false;
      }

      return userRef.current.roles.includes(roleSlug);
    },
    [],
  );

  const updateMyProfile = useCallback(async (values) => {
    await mutationFn('PUT', [AuthMiddleware], {endpoint: '/api/profile/me', body: values}, {auth, user: {}});
    statusRef.current?.refetch();
  }, []);

  const updatePhoto = useCallback(async (photo) => {
    const internalUrl = URL.createObjectURL(photo);
    const image = new Image();
    image.addEventListener('load', async (evt) => {
      const img = evt.target;
      URL.revokeObjectURL(img.src);
      const aspectRatio = img.width / img.height;
      const maxWidth = 250;
      const maxHeight = 250;
      const width = aspectRatio > 1 ? maxWidth : maxWidth * aspectRatio;
      const height = aspectRatio > 1 ? maxHeight / aspectRatio : maxHeight;

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      const dataUrl = canvas.toDataURL('image/jpeg');

      const byteStr = atob(dataUrl.split(',')[1]);
      const mimeStr = dataUrl.split(',')[0].split(':')[1].split(';')[0];

      const arrayBuffer = new ArrayBuffer(byteStr.length);
      const dataView = new DataView(arrayBuffer);
      for (let i = 0; i < byteStr.length; i++) {
        dataView.setUint8(i, byteStr.charCodeAt(i));
      }
      const data = new Blob([arrayBuffer], {type: mimeStr});

      canvas.remove();

      const formData = new FormData();
      formData.append('file', new File([data], photo.name, {type: mimeStr}));
      await mutationFn('POST', [AuthMiddleware], {endpoint: '/api/profile/photo', body: formData}, {auth, user: {}});
      statusRef.current?.refetch();
    });
    image.src = internalUrl;
  }, []);

  const deletePhoto = useCallback(async () => {
    await mutationFn('DELETE', [AuthMiddleware], {endpoint: '/api/profile/photo'}, {auth, user: {}});
    statusRef.current?.refetch();
  }, []);

  const updateNotificationFrequency = useCallback(async (values) => {
    await mutationFn('PUT', [AuthMiddleware], {endpoint: '/api/notification/frequency', body: {frequency: values}}, {
      auth,
      user: {}
    });
    statusRef.current?.refetch();
  }, []);

  const values = useMemo(
    () => ({
      user,
      hasPermission,
      hasRole,
      ready,
      updateMyProfile,
      updatePhoto,
      deletePhoto,
      updateNotificationFrequency,
    }),
    [user, hasPermission, hasRole, ready, updateMyProfile, updatePhoto, deletePhoto, updateNotificationFrequency],
  );

  return (
    <UserContext.Provider value={values}>
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => useContext(UserContext);
